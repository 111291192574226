import styled from "styled-components"
import { Flex, Image } from "src/styled"
import { devices } from "src/theme/device"
import Link from "./Link"

export const Logo = styled.img`
    margin: auto;
    height: 50px;
    width: auto;
    cursor: pointer;
`

export const StyledLink = styled(Link)`
    ${devices.laptop} {
        font-size: 14px;
        margin: auto 10px;
    }
    ${devices.tablet} {
        display: none;
    }
`

export const Badge = styled(Flex)`
    right: -12px;
    top: -8px;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    font-size: 14px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: white;
    z-index: 99;
    background: ${({ theme }) => theme.colors.danger};
    border-radius: 50%;
`

export const NavContainer = styled.nav`
  display: flex;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  padding: 10px;
  background: white;
  z-index: 2;
  transition: 0.2s ease-in;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
`

export const NavWrapper = styled(Flex)`
    ${devices.tablet} {
        display: none;
    }
    justify-content: flex-start;
    flex: 1;
`

export const BasketButton = styled(Link.withComponent("button"))`
    margin-right: 10px !important;
    margin-top: 5px !important;
    :hover,
    :focus {
        #basket {
        }
    }
`

/*
 display: block;
*/

export const StyledImage = styled(Image)`
    margin-top: 5px;
    height: 35px;
    width: 30px;
`
